import React, { useCallback, useContext, useEffect } from "react";
import { hooks } from "botframework-webchat";
import classNames from "classnames";

// import css and context
import ChatContext from "../../Context/Context";
import "./MicrophoneButton.css";

//import assets
import activemic from "../../Assets/active-mic.svg";
import disablemic from "../../Assets/disable-mic.svg";
import micDisablePer from "../../Assets/permanent-mic-disable.svg";
import audiofile from "../../Assets/audio/beeep.mp3";
var audio = new Audio(audiofile);

//import hooks from the webchat.....
const {
  useMicrophoneButtonClick,
  useMicrophoneButtonDisabled,
  useSendBoxSpeechInterimsVisible,
} = hooks;
const CustomMicrophoneButton = ({ onClick, setActive, active }) => {
  const [interimsVisible] = useSendBoxSpeechInterimsVisible();
  const [disabled] = useMicrophoneButtonDisabled();
  const { hasFlowEnded, state } = useContext(ChatContext);

  const click = useMicrophoneButtonClick();
  const handleClick = useCallback(() => {
    !interimsVisible && click();
    onClick && onClick();
  }, [click, onClick, interimsVisible]);
  useEffect(() => {
    if (interimsVisible === true) {
      !state.muteSpeak && audio.play();
    }
  }, [interimsVisible, state]);
  return (
    <button
      disabled={hasFlowEnded}
      style={{ border: "none", background: "none" }}
      className={classNames("chat-btn", {
        dictating: interimsVisible,
      })}
      onClick={handleClick}
    >
      <img
        onContextMenu={(e) => e.preventDefault()}
        style={{ width: "100%" }}
        alt="microphoen-icon"
        src={hasFlowEnded ? micDisablePer : disabled ? activemic : disablemic}
      />
    </button>
  );
};
export default CustomMicrophoneButton;
