import React, { useEffect, useContext } from "react";
import { hooks } from "botframework-webchat";

// component and styling imports
import Modal from "react-bootstrap/Modal";
import "../BotJourneyEndCard/BotJourneyEnd.css";

//import context
import ChatContext from "../../Context/Context";

// destructure hooks of webchat
const { useActivities, useConnectivityStatus } = hooks;

export default function BotJourneyENDCard() {
  const { directLineSpeechAdapters, hasFlowEnded, setHasFlowEnded } =
    useContext(ChatContext);

    //bot hooks define
  const [activities] = useActivities();
  const [connectionStatus] = useConnectivityStatus();

  useEffect(() => {
    if (
      activities[activities.length - 1].name === "endOfFlow" &&
      activities[activities.length - 1].type === "event" &&
      connectionStatus === "connected"
    ) {
      setHasFlowEnded(true);
      !!directLineSpeechAdapters && directLineSpeechAdapters.directLine.end();
    }
  }, [
    activities,
    hasFlowEnded,
    connectionStatus,
    directLineSpeechAdapters,
    setHasFlowEnded,
  ]);

  return (
    <div className="outer-end-modal">
      {hasFlowEnded && (
        <div
          className="end-modal-card-main "
          style={{ display: "block", position: "initial" }}
        >
          <Modal.Dialog>
            <Modal.Body className="end-modal-card-main-body">
              <div className="display-flex-property">
                <p className="end-first-text">This conversation has ended, </p>
                <a
                  href={() => false}
                  className="end-second-text"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Restart conversation
                  </div>
                </a>
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      )}
    </div>
  );
}
