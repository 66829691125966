import { useMemo } from "react";
import "../chat.css";

import userAvtar from "../../../Assets/user-avatar.svg";

function UserChat({ index, msgText }) {
  //date memomised using use memo hook for the time stamp
  const DateMemoMized = useMemo(
    () =>
      new Date().toLocaleString("en-IN", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
    []
  );





  // user chat render
  return (
    <div className="chat-right" key={index}>
      <div className="chatuser-with-ts">
        <div className="chat-box-user">
          <div>{msgText}</div>
        </div>
        <div className="time-stamp-user">{DateMemoMized}</div>
      </div>
      <img
        onContextMenu={(e) => e.preventDefault()}
        className="user-bot-avtar"
        alt="use-avtar"
        src={userAvtar}
      />
    </div>
  );
}

export default UserChat;
