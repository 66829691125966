import { useMemo } from "react";
import "../chat.css";
import botAvtar from "../../../Assets/chatbot-avatar.webp";

//import card
import Choiceprompt from "../../ChoicePromptCard/Choiceprompt";
import SummaryCard from "../../SummaryCard/SummaryCard";
import HyperlinkCard from "../../Hyperlinkcard/HyperlinkCard";

function BotChat({ message, index }) {
  //date memomised using use memo hook for the time stamp
  const DateMemoMized = useMemo(
    () =>
      new Date().toLocaleString("en-IN", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
    []
  );

  // bot side activity handler
  const renderBotMessage = (activityObj) => {
    //choice prompt card condition
    if (activityObj?.channelData && activityObj?.channelData?.choices) {
      let choiceResponse = activityObj?.channelData?.choices;
      return (
        <Choiceprompt
          choiceResponse={choiceResponse}
          text={activityObj?.text}
        />
      );
    }

    // summary card conditions
    else if (activityObj?.channelData && activityObj?.channelData?.summary) {
      let summary = activityObj?.channelData?.summary;
      return <SummaryCard summary={summary} text={activityObj?.text} />;
    }

    //hyperlink card condition
    else if (activityObj?.channelData && activityObj?.channelData?.url) {
      let url = activityObj?.channelData?.url;
      return <HyperlinkCard href={url} text={activityObj?.text} />;
    }
    return activityObj?.text;
  };

  //bot chat render
  return (
    <div className="chat-left" key={index}>
      <img
        onContextMenu={(e) => e.preventDefault()}
        className="user-bot-avtar"
        alt="bot-avtar"
        src={botAvtar}
      />
      <div className="chat-box-bot-outer">
        <div className="chat-box-bot">
          <div>{renderBotMessage(message)}</div>
        </div>
        <div className="time-stamp-bot">{DateMemoMized}</div>
      </div>
    </div>
  );
}

export default BotChat;
