import React, { useContext } from "react";
//  import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import ChatContext from "../../Context/Context";
import './toast.css'
//import assets 
import deniedPermisionIcon from '../../Assets/toast-mic.svg'

function ErrorCard() {
  const { toast, setToast } = useContext(ChatContext);
  return (
    <div className="toast-parentcard" zindex={999} >
      <Toast style={{border : "none"}} onClose={() => setToast(false)} show={toast} delay={5000} autohide>
        <div className="toast-body" >
          <img src={deniedPermisionIcon} style={{width: "44px"}} alt="permision" onContextMenu={(e) => e.preventDefault()}/>
          <p className="text-permission"> It seems like, you have not granted access to the mic, please allow access to proceed</p>
        </div>
      </Toast>
    </div>
  );
}

export default ErrorCard;