import React from "react";
import "./summary.css";
// summary card render component.
function SummaryCard({ summary, text }) {
  return (
    <>
      <div className="headaer-summ">{text}</div>
      <div className="parent-summary-div">
        {summary.map((sumary, index) => (
          <div>
            <div style={{ fontSize: 15 }} className="header-title">
              {sumary.header.title}
            </div>
            <div>
              {sumary.sections.map((val, i) => (
                <div className="key-value-pairset" key={i}>
                  <div className="title-set">{`${i + 1}. ${val.title}`}</div>
                  <div>
                    {val.fields.map((ans, ind) => (
                      <div className="name-title">
                        <div
                          className={
                            ind + 1 === val.fields.length
                              ? "val-div2"
                              : "name-div"
                          }
                          key={ind}
                        >
                          {ans.name}
                        </div>
                        <h7
                          className={
                            ind + 1 === val.fields.length
                              ? "val-div2"
                              : "vlaue-div"
                          }
                          key={ind}
                        >
                          {ans.value}
                        </h7>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default SummaryCard;
