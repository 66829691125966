import React from "react";
import "./choice.css";
function Choiceprompt({ choiceResponse, text }) {
  return (
    <div>
      <div className="choice-head-css">{text}</div>
      <div className="display-choices">
        {choiceResponse.map((res, i) => (
          <div className="indv-price" key={i} style={{ width: "fit-content" }}>
            {res}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Choiceprompt;
