import React, { useContext } from "react";

// import components
import Header from "./Components/Header/Header";
import ModalSpashScreen from "./Components/ModalSplashScreen/ModalSpalshScreen";
import ChatContext from "./Context/Context";
import Main from "./Pages/Main";

// error boundry to catch uncought errr...
import ErrorBoundary from "./Components/ErrorBoundary";

// import application insights ....
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// import { computeHeadingLevel } from "@testing-library/react";
//azure insight for the back tracking of the bug ....
if (process.env.REACT_APP_INSIGHT) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_INSIGHT,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

function App() {
  const { active } = useContext(ChatContext);
  return (
    <ErrorBoundary>
      <div>
        <Header />
        <div>{active ? <ModalSpashScreen /> : <Main />}</div>
      </div>
    </ErrorBoundary>
  );
}
export default App;
