import React, { useRef, useEffect, useContext } from "react";
import { hooks } from "botframework-webchat";
import { isMobile, useDevice } from "use-ua-parser-js";
import { Components } from "botframework-webchat";
import "./chat.css";

//import assets for the chat
import activespeaker from "../../Assets/active-speaker.svg";
import disablespeaker from "../../Assets/disable-speaker.svg";
import speakerDisablePer from "../../Assets/permanent-speaker-disable.svg";

//import components
import BotJourneyENDCard from "../BotJourneyEndCard/BotJourneyENdCard.js";
import CustomMicrophoneButton from "../Microphoneservices/MicrophoneButton";
import UserChat from "./UserChat/UserChat";
import BotChat from "./BotChat/BotChat";

// import context
import ChatContext from "../../Context/Context";

//define hooks and component
const {
  useActivities,
  useMicrophoneButtonDisabled,
  useShouldSpeakIncomingActivity,
} = hooks;
const { SpeakActivity } = Components;

const Chatecho = () => {
  // define context
  const {
    hasFlowEnded,
    setHasFlowEnded,
    state,
    setstate,
    conversationId,
    setConversationId,
    visiblilty: isTabVisible,
    directLineSpeechAdapters,
    setToggle,
    toggle,
  } = useContext(ChatContext);

  //define hooks
  const [activities] = useActivities();
  const [disabled] = useMicrophoneButtonDisabled();
  const [ShouldSpeakIncomingActivity, setShouldSpeakIncomingActivity] =
    useShouldSpeakIncomingActivity();


  //define device info.
  const device = useDevice();
  const isCurrentDeviceMobile = isMobile(device);

  // scroll disable for the chat
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  //mutespak toggle function
  const handleClick = () => {
    setToggle(!toggle);
    setstate({ muteSpeak: !state.muteSpeak });
  };

  useEffect(() => {
    // speak first activity
    activities.length === 0 && setShouldSpeakIncomingActivity(true);
    activities.length === 0 &&
      setInterval(() => {
        if (
          !hasFlowEnded &&
          directLineSpeechAdapters &&
          directLineSpeechAdapters?.directLine?.dialogServiceConnector
            ?.privIsDisposed
        ) {
          setHasFlowEnded(true);
        }
      }, 1000);
  }, [
    activities,
    setShouldSpeakIncomingActivity,
    directLineSpeechAdapters,
    setHasFlowEnded,
    hasFlowEnded,
  ]);

  useEffect(() => {
    //capture and display conversationId (TODO remove in prod)
    !conversationId &&
      activities.length > 0 &&
      setConversationId(
        activities.find(
          ({ from: { role }, type }) => role === "bot" && type === "message"
        )?.conversation?.id
      );
  }, [activities, setConversationId, conversationId]);

  //render user and bot chat
  return (
    <div className="chat-outside">
      <div className="chat-main-div">
        <div className="chat-main">
          {activities?.length > 0 && (
            <div>
              {activities.map(
                (message, index) =>
                  message.type === "message" &&
                  (message.from.role === "user" ? (
                    <div key={index}>
                      <UserChat index={index} msgText={message.text} />
                    </div>
                  ) : (
                    <div key={index}>
                      <BotChat message={message} index={index} />
                      {message?.from?.role === "bot" &&
                        !state.muteSpeak &&
                        (!isCurrentDeviceMobile || isTabVisible) &&
                        message?.channelData?.speak && (
                          <SpeakActivity activity={message} index={index} />
                        )}
                    </div>
                  ))
              )}
              <BotJourneyENDCard />
              <AlwaysScrollToBottom />
            </div>
          )}
        </div>
        <div
          className="chat-btns-div"
          style={disabled ? { background: "transparent" } : null}
        >
          <CustomMicrophoneButton />
          {!disabled ? (
            <img
              onContextMenu={(e) => e.preventDefault()}
              className="chat-btn mute-button"
              onClick={() => handleClick()}
              src={
                hasFlowEnded
                  ? speakerDisablePer
                  : state.muteSpeak
                  ? disablespeaker
                  : activespeaker
              }
              alt="active mic"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Chatecho;
