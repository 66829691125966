import React, { useContext } from "react";
//  import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import ChatContext from "../../Context/Context";
import "./permisiontoast.css";

//import assets
import Warning from "../../Assets/warning.svg";

function PermissionToastCar() {
  const { apiError, setApiError } = useContext(ChatContext);
  return (
    <div style={{ backgroundColor: "FFFBF0" }} className="toast-parentcard">
      <Toast
        style={{ border: "none" }}
        onClose={() => setApiError(false)}
        show={apiError}
        zindex={999}
      >
        <div className="toast-body">
          <img
            src={Warning}
            style={{ width: "44px" }}
            alt="permision"
            onContextMenu={(e) => e.preventDefault()}
          />
          <p className="text-permission">
            I am sorry, we are experiencing a technical glitch at the moment,
            please try after sometime
          </p>
        </div>
      </Toast>
    </div>
  );
}

export default PermissionToastCar;
