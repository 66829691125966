import { useEffect, useContext } from "react";
import { hooks } from "botframework-webchat";
import {
  useDevice,
  isMobile,
  useBrowser,
  isTouchDevice,
} from "use-ua-parser-js";
import { useCallback } from "react";
import ChatContext from "../../Context/Context";
const { useConnectivityStatus, usePostActivity } = hooks;
function DeaviceInfo() {
  const { ipAddress } = useContext(ChatContext);
  const [connectionStatus] = useConnectivityStatus();
  const postAcitivity = usePostActivity();
  const device = useDevice();
  const browser = useBrowser();
  const isCurrentDeviceMobile = isMobile(device);
  const touchDevice = isTouchDevice(device);
  function getQueryVariable(variable) {
    const query = window.location.search.slice(1) ?? "";
    const vars = query.split("&");
    for (const var_ of vars) {
      const pair = var_.split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  const sendEvent = useCallback(() => {
    const deviceInfoPayload = {
      ipAddress: ipAddress,
      device: device,
      browser: browser,
      isMobileDevice: isCurrentDeviceMobile,
      isTouchDevice: touchDevice,
      channel: getQueryVariable("channel") ?? false,
    };
    postAcitivity({
      type: "event",
      name: "deviceInfo",
      value: deviceInfoPayload,
    });
  }, [
    device,
    browser,
    isCurrentDeviceMobile,
    touchDevice,
    postAcitivity,
    ipAddress,
  ]);

  useEffect(() => {
    if (connectionStatus === "connected") {
      sendEvent();
    }
  }, [connectionStatus, sendEvent]);
}

export default DeaviceInfo;
