import React, { useEffect, useState } from "react";
import "./BlurLens.css";
import classNames from "classnames";
import { hooks } from "botframework-webchat";
const { useSendBoxSpeechInterimsVisible } = hooks;
const BlurLens = () => {
  const [active, setActive] = useState(false);
  const [interimsVisible] = useSendBoxSpeechInterimsVisible();
  let intVisible = !interimsVisible;
  useEffect(() => {
    setActive((prevCheck) => !prevCheck);
  }, [intVisible, setActive]);
  return !active ? (
    <div className={classNames("App-BlurLens", { hide: !interimsVisible })} />
  ) : null;
};

export default BlurLens;
