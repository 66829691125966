import React, { useState, createContext } from "react";
const ChatContext = createContext();
function ContextComponent({ children }) {
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(true);
  const [directLineSpeechAdapters, setDirectLineSpeechAdapters] = useState();
  const [hasFlowEnded, setHasFlowEnded] = useState(false);
  const [state, setstate] = useState({ muteSpeak: false });
  const [conversationId, setConversationId] = useState("");
  const [visiblilty, setVisibility] = useState(true);
  const [micDeniedPermisions, setMicDeniedPermission] = useState("");
  const [toggle, setToggle] = useState(false);
  const [toast, setToast] = useState(false);
  const [tokenFetched, setTokenFetched] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [ipAddress, setIpaddress] = useState(0)


  return (
    <ChatContext.Provider
      value={{
        setShow,show,
        active,setActive,
        directLineSpeechAdapters,setDirectLineSpeechAdapters,
        hasFlowEnded,setHasFlowEnded,
        state,setstate,
        conversationId,setConversationId,
        visiblilty,setVisibility,
        micDeniedPermisions,setMicDeniedPermission,
        setToggle,toggle,
        toast,setToast,
        apiError,setApiError,
        tokenFetched,setTokenFetched,
        ipAddress, setIpaddress,
       }}
    >
      {children}
    </ChatContext.Provider>
  );
}
export default ChatContext;
export { ContextComponent };
