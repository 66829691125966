import React from "react";
import "./hyperlink.css";
import Button from "react-bootstrap/Button";

//hyper link card
function HyperlinkCard({ href, text }) {
  return (
    <div>
      <div className="hyper-head">
        {text}
      </div>
      <a
        href={href}
        className="d-flex justify-content-center align-items-center"
      >
        <Button className="start-btn  btn-outline-light">Pay Now!</Button>
      </a>
    </div>
  );
}

export default HyperlinkCard;
