const RENEW_EVERY = 240000;
let fetchPromise;
let lastFetch = 0;
let count = 0;
let countAPI = 0;

async function fetchCredentials() {
    count++;
    const now = Date.now();

    if (!fetchPromise || now - lastFetch > RENEW_EVERY) {
        if (countAPI > 1 && fetchPromise) {
            // console.log(
            //     "not fetching a token from API.. blocked",
            //     { count, countAPI },
            //     new Date()
            // );
            return fetchPromise;
        }
        countAPI++;
        // timeout request after 2 sec
        const controller = new AbortController();
        // const timeoutId = setTimeout(() => controller.abort(), 2000);
        // make the request
        fetchPromise = fetch(process.env.REACT_APP_KEY, { method: "POST", signal: controller.signal })
            .then((res) => res.json())
            .then(({ip, region, token }) => {
                // clearTimeout(timeoutId);
                return {ip : ip ,authorizationToken: token, region};
            });

        lastFetch = now;
        // console.log("fetching token from API", { count, countAPI }, new Date());
    } else {
        // console.log("fetching token from CACHE", { count, countAPI }, new Date());
    }
    return fetchPromise;
}

export default fetchCredentials;
