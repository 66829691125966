import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";

import logo from "../../../src/Assets/logo 2.svg";

function Header() {
  return (
    <div>
      <Navbar
        style={{
          backgroundColor: "#D0F6FF",
          position: "fixed",
          top: "0",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Container fluid>
          <Navbar.Brand
            id="home"
            href="https://www.reliancegeneral.co.in/Insurance/Home.aspx"
          >
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={logo}
              className="d-inline-block align-top fluid nav-img"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
