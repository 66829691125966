import { click } from "@testing-library/user-event/dist/click";
import React, { useEffect, useRef, useContext, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalSpashScreen.css";

// fetch credential call Api ...
import fetchCredentials from "../../Credentials/fetchSpeechServicesCredentials";

//import tost component component
import PermissionToastCard from "../PermisionToastCard/PermissionToastCard";
import ErrorCard from "../PermisionToastCard/ErrorCard";

//import contexts
import ChatContext from "../../Context/Context";

//import assets
import mic from "../../Assets/mic.png";
import micsvg from "../../Assets/micsvg.svg";
import speakersvg from "../../Assets/speakersvg.svg";
import topBG from "../../Assets/tr-bg.png";
import bottomBG from "../../Assets/bl-bg.png";

function ModalSpashScreen() {
  const {
    show,
    setShow,
    setActive,
    setMicDeniedPermission,
    micDeniedPermisions,
    setToast,
    setPermission,
    setApiError,
    apiError,
    tokenFetched,
    setTokenFetched,
    setIpaddress,
  } = useContext(ChatContext);

  const audioRef = useRef();

  useEffect(() => {
    const getLocalStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioRef.current.srcObject = stream;
      } catch (err) {
        if (
          err instanceof DOMException &&
          err.message === "Permission denied"
        ) {
          setMicDeniedPermission("micPermisionWasBlocked");
        }
      }
    };
    getLocalStream();
    fetchCredentials()
      .then((e) =>{ setTokenFetched(true)
        setIpaddress(e.ip)
        })
      .catch((err) => setApiError(true));
  }, [setMicDeniedPermission, setPermission, setApiError, setTokenFetched,setIpaddress]);

  const handleClose = useCallback(() => {
    if (micDeniedPermisions === "micPermisionWasBlocked") {
      setToast(true); // temprory comment
      setShow(true);
      setActive(true);
    } else {
      setShow(false);
      setActive(false); // test the functionality for the mic permisions.
    }
  }, [setShow, setActive, micDeniedPermisions, setToast]);

  useEffect(() => {
    window.addEventListener(click, handleClose);
  }, [handleClose]);

  return (
    <>
      <Modal
        ref={audioRef}
        show={show}
        className="main-component ripple"
        centered
      >
        <img
          onContextMenu={(e) => e?.preventDefault()}
          className="tr-bg"
          src={topBG}
          alt="topBG"
        />
        <img
          onContextMenu={(e) => e?.preventDefault()}
          className="bl-bg"
          src={bottomBG}
          alt="bottomBG"
        />
        <Modal.Body className="modalbody d-flex flex-column  justify-content-center align-items-center py-2">
          <div className="mic-img-div d-flex justify-content-center align-items-center ">
            <img
              onContextMenu={(e) => e.preventDefault()}
              className="mic"
              src={mic}
              alt="mic"
            />
          </div>

          <p className="permission-sub-text pb-2" style={{ color: "#0C4DA2" }}>
            <span className="permission -icon">
              <img
                onContextMenu={(e) => e?.preventDefault()}
                className="permissionsvg"
                src={micsvg}
                alt="micsvg"
              />
            </span>
            We will access your Mic
          </p>
          <p className="permission-sub-text pt-1 pb-3" style={{ color: "#0C4DA2" }}>
            <span className="permission-icon">
              <img
                onContextMenu={(e) => e.preventDefault()}
                className="permissionsvg"
                src={speakersvg}
                alt="micsvg"
              />
            </span>
            Turn on your speaker
          </p>

          <Button
            onClick={() => handleClose()}
            className="start-btn  btn-outline-light m-3 "
            disabled={apiError || !tokenFetched}
          >
            Start Your Journey
          </Button>
        </Modal.Body>
      </Modal>
      <div className="PermissionToastCard">
        <ErrorCard />
        <PermissionToastCard />
      </div>
    </>
  );
}

export default ModalSpashScreen;
