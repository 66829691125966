import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextComponent } from "./Context/Context";
import ErrorBoundary from "./Components/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextComponent>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ContextComponent>
);
reportWebVitals();
