import React, { useEffect, useContext } from "react";
import ChatContext from "../Context/Context";
import {
  createDirectLineSpeechAdapters,
  Components,
} from "botframework-webchat";
import DisplayComponent from "../Components/DisplayComponent/DisplayComponent";
import fetchCredentials from "../Credentials/fetchSpeechServicesCredentials";
import { Connection } from "microsoft-cognitiveservices-speech-sdk";
// error boudry to caught uncought errr..
import ErrorBoundary from "../Components/ErrorBoundary";

const { Composer } = Components;
export default function Main() {
  const {
    directLineSpeechAdapters,
    setDirectLineSpeechAdapters,
    visiblilty: isTabVisible,
    setVisibility,
  } = useContext(ChatContext);
  useEffect(() => {
    (async () => {
      const adapters = await createDirectLineSpeechAdapters({
        fetchCredentials,
        speechRecognitionLanguage: "en-IN",
      });
      // increase segmentation silence timeout based on user feedback
      // based on https://stackoverflow.com/questions/73322254/how-to-change-azure-text-to-speech-silence-timeout-in-javascript
      const conn = Connection.fromRecognizer(
        adapters.directLine.dialogServiceConnector
      );
      conn.setMessageProperty("speech.context", "phraseDetection", {
        INTERACTIVE: {
          segmentation: {
            mode: "Custom",
            segmentationSilenceTimeoutMs: 1250,
          },
        },
        mode: "Interactive",
      });
      // save a ref on the window for easy access
      window.dlAdapters = adapters;
      return setDirectLineSpeechAdapters(adapters);
    })();
  }, [setDirectLineSpeechAdapters]);

  useEffect(() => {
    // detect visibility changes in the tab
    document.addEventListener("visibilitychange", function (event) {
      if (document.hidden) {
        setVisibility(false);
      } else {
        setVisibility(true);
      }
    });
  }, [setVisibility]);
  // Function that attempts to request a screen wake lock.

  const requestWakeLock = async () => {
    try {
      if (navigator.wakeLock) {
        window._wakeLock = await navigator.wakeLock.request();
      } else {
        // browser does not support wakeLock
      }
    } catch (err) {
      // console.error(`${err.name}, ${err.message}`);
    }
  };

  useEffect(() => {
    // wake lock for mobile screen timeout
    if (isTabVisible && (window._wakeLock?.released || !window._wakeLock)) {
      requestWakeLock();
    } else {
      window.setTimeout(() => {
        window._wakeLock?.release && window._wakeLock.release();
        window._wakeLock = null;
      }, 10);
    }
  }, [isTabVisible]);

  return (
    <ErrorBoundary>
      {!!directLineSpeechAdapters && (
        <Composer {...directLineSpeechAdapters}>
          <DisplayComponent />
        </Composer>
      )}
    </ErrorBoundary>
  );
}
