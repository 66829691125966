import DeaviceInfo from "../DeviceInformation/DeaviceInfo";
import CustomDictationInterims from "../UserResponseRecordIntrims/SpeechInterims";
import Chatecho from "../ChatComponent/Chatecho";
import BlurLens from "../UserResponseRecordIntrims/BlurLens";

function DisplayComponent() {
  
  return (
    <>
      <Chatecho />
      <BlurLens />
      <CustomDictationInterims />
      <DeaviceInfo />
    </>
  );
}

export default DisplayComponent;
